import { RefreshToken } from './../models/logged-user.model'
import { HttpBackendService } from './http-backend.service'
import { Observable } from 'rxjs'
import { Injectable } from '@angular/core'
import { MainResponse } from 'projects/crm/src/app/shared/models'
import { shareReplay } from 'rxjs/operators'
import { LoggedUser, UpdatePassword, ForgotPassword } from '../models'

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  url = ''

  constructor(private httpBackendService: HttpBackendService) {}

  logIn(payload): Observable<MainResponse<LoggedUser>> {
    const url = `${this.url}User/Login`
    return this.httpBackendService
      .post<MainResponse<LoggedUser>>(url, payload)
      .pipe(shareReplay())
  }

  forgotPassword(payload): Observable<MainResponse<ForgotPassword>> {
    const url = `${this.url}User/ForgotPassword`
    return this.httpBackendService.post<MainResponse<ForgotPassword>>(
      url,
      payload
    )
  }

  updatePassword(payload): Observable<MainResponse<UpdatePassword>> {
    const url = `${this.url}User/UpdatePassword`
    return this.httpBackendService.post<MainResponse<UpdatePassword>>(
      url,
      payload
    )
  }

  getAccessTokenByRefreshToken(
    payload: RefreshToken
  ): Observable<MainResponse<string>> {
    const url = `${this.url}User/RefreshAccessToken`
    return this.httpBackendService.post<MainResponse<string>>(url, payload)
  }
}
