export const PositiveNumbers = '(^0.5$)|(^[1-9][0-9]*(.[05])?$)'
export const PositiveNumbersAndZero = '(^0.5$)|(^[0-9][0-9]*(.[05])?$)'
export const PositiveDecimalNumbers = /^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,2}$/
export const PositiveDecimalNumbersAndZero = /^0|(?!0*[.]0*$|[.]0*$|0*$)\d+[.]?\d{0,2}$/
export const NoSpace = /^\S*$/
export const NoWhiteSpaces = /^[^\s]+(\s+[^\s]+)*$/
export const IP = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
export const URL = /^$|[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
export const dnsOrIp = /^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?|^((http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/

// Minimum 5, maximum 15 characters, one uppercase letter and one number
export const Password = /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{5,15}$/

// Only numbers and letters
export const LettersAndNumbers = /^[a-zA-Z0-9]*$/ 