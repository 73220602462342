import { Injectable } from '@angular/core'
import { Query } from '@datorama/akita'
import { BrandSwitchStore, BrandSwitchState } from './brand-switch.store'

@Injectable({ providedIn: 'root' })
export class BrandSwitchQuery extends Query<BrandSwitchState> {
  brandId$ = this.select('brandId')
  brandName$ = this.select('brandName')
  userInfo$ = this.select(['brandId', 'languageId', 'currencyId'])
  constructor(protected store: BrandSwitchStore) {
    super(store)
    this.saveToStorage()
  }
  saveToStorage() {
    this.select('brandId').subscribe(state => {
      if (state) {
        localStorage.setItem('brandId', JSON.stringify(state))
      }
    })
  }
}
