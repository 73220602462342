<div class="h-100 bg-color-primary bg-animation">
  <div class="d-flex h-100 justify-content-center align-items-center">
    <div class="mx-auto app-login-box col-md-8">
      <div class="app-logo-inverse mx-auto mb-3"></div>
      <form [formGroup]="loginForm" (ngSubmit)="logIn()">
        <div class="modal-dialog w-100 mx-auto">
          <div class="modal-content card">
            <div class="modal-body">
              <div class="h5 text-center">
                <div class="logo mt-3 mb-4">
                  <img src="../../../assets/images/logo.svg" alt="" />
                </div>
              </div>
              <!-- === Login === -->
              <div class="header">
                <h1>Welcome back,</h1>
                <h5>Please log in to your account below.</h5>
              </div>
              <div
                id="exampleInputGroup1"
                role="group"
                aria-describedby="exampleInputGroup1__BV_description_"
                class="form-group InputGroup1"
                *ngIf="!enableTWOFA"
              >
                <div>
                  <label for="email">Email</label>
                  <input
                    id="email"
                    formControlName="email"
                    type="text"
                    placeholder="Enter email..."
                    required="required"
                    aria-required="true"
                    class="form-control"
                    aria-describedby="exampleInputGroup1__BV_description_"
                    (change)="trimInputValue(loginForm.get('email'))"
                    (keydown.space)="$event.preventDefault()"
                  />
                  <small
                    tabindex="-1"
                    id="exampleInputGroup1__BV_description_"
                    class="form-text text-muted"
                    >We'll never share your email with anyone else.
                  </small>
                </div>
              </div>
              <div
                id="exampleInputGroup2"
                role="group"
                class="form-group"
                *ngIf="!enableTWOFA"
              >
                <label for="exampleInput2">Password</label>
                <div class="input-group">
                  <input
                    id="exampleInput2"
                    formControlName="password"
                    [type]="fieldTextType ? 'text' : 'password'"
                    placeholder="Enter password..."
                    required="required"
                    aria-required="true"
                    class="form-control"
                    (change)="trimInputValue(loginForm.get('password'))"
                    (keydown.space)="$event.preventDefault()"
                  />
                  <div
                    (click)="fieldTextType = !fieldTextType"
                    class="input-group-append password-show-hide"
                  >
                    <span class="input-group-text">
                      <i class="pe-7s-look"></i>
                    </span>
                  </div>
                </div>
                <div class="logging-in d-flex justify-content-between">
                  <!-- <input
                    id="exampleCheck"
                    type="checkbox"
                    name="check"
                    class="custom-control-input"
                    value="true"
                  /><label for="exampleCheck" class="custom-control-label">
                    Keep me logged in
                  </label> -->
                  <mat-checkbox>
                    Keep me logged in
                  </mat-checkbox>
                  <a [routerLink]="['/forgot-password']">Forgot Password</a>
                </div>
              </div>
              <!-- === OTP === -->
              <div class="otp-container" *ngIf="enableTWOFA">
                <div class="header">
                  <h1>Two-step verification,</h1>
                  <h5>
                    For additional security, please enter One Time Password
                    below.
                  </h5>
                </div>

                <div class="form-group">
                  <label for="code">Enter OTP</label>
                  <input
                    id="code"
                    formControlName="code"
                    type="text"
                    placeholder=""
                    required="required"
                    aria-required="true"
                    class="form-control"
                    aria-describedby=""
                    (change)="trimInputValue(loginForm.get('code'))"
                    (keydown.space)="$event.preventDefault()"
                  />
                </div>
              </div>
            </div>

            <button
              [disabled]="!loginForm.valid"
              type="submit"
              class="btn btn-primary btn-lg login-button"
            >
              Login
            </button>
          </div>
        </div>
      </form>
      <!-- <div class="text-center text-white opacity-8 mt-3">
        Copyright © Applebrie {{ currentYear }}
      </div> -->
    </div>
  </div>
</div>
