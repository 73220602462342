import { domains } from './domains'

export const environment = {
  production: true,
  appVersion: require('../../../../package.json').version,
  timeStamp: '',
  fakeBaseApiUrl: '',
  baseApiUrl: `https://gw${domains.prod}/api`,
  mt4pumpingHub: `https://mt4hub${domains.prod}/notify`,
  notificationHub: `https://notification${domains.prod}/notify`,
  pumpingHub: `https://pumping-hub${domains.prod}/notify`,
  userID: 362,
}
