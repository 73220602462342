<div class="h-100 bg-color-primary bg-animation">
  <div class="d-flex h-100 justify-content-center align-items-center">
    <div class="mx-auto app-login-box col-md-6">
      <div class="app-logo-inverse mx-auto mb-3"></div>
      <div class="modal-dialog w-100">
        <div class="modal-content">
          <div class="modal-header">
            <div class="h5 modal-title">
              Forgot your Password?
              <h6 class="mt-1 mb-0 opacity-8">
                <span>Use the form below to recover it.</span>
              </h6>
            </div>
          </div>
          <div class="modal-body">
            <div>
              <form [formGroup]="forgotPasswordForm">
                <div class="row">
                  <div class="col-md-12">
                    <fieldset class="form-group" id="__BVID__132">
                      <div tabindex="-1" role="group">
                        <label for="exampleEmail">Email</label>
                        <input
                          formControlName="email"
                          id="exampleEmail"
                          name="email"
                          type="email"
                          placeholder="Email here..."
                          class="form-control"
                        />
                      </div>
                    </fieldset>
                  </div>
                </div>
              </form>

              <form
                *ngIf="recoverPasswordForm"
                [formGroup]="updatePasswordForm"
                (ngSubmit)="updatePassword()"
              >
                <div id="exampleInputGroup12" role="group" class="form-group">
                  <div>
                    <label for="exampleEmail">Recover password</label>
                    <input
                      formControlName="code"
                      id="exampleInput12"
                      type="text"
                      placeholder="Enter code..."
                      required="required"
                      aria-required="true"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div
                      id="exampleInputGroup2"
                      role="group"
                      class="form-group"
                    >
                      <div>
                        <input
                          formControlName="newPassword"
                          id="exampleInput2"
                          type="password"
                          placeholder="Enter password..."
                          required="required"
                          aria-required="true"
                          class="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div
                      id="exampleInputGroup2"
                      role="group"
                      class="form-group"
                    >
                      <div>
                        <input
                          formControlName="password"
                          id="exampleInput2"
                          type="password"
                          placeholder="Repeat password..."
                          required="required"
                          aria-required="true"
                          class="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 pass-error" *ngIf="showPassError">
                    Password mismatch
                  </div>
                </div>
              </form>
            </div>
            <div class="divider"></div>
            <h6 class="mb-0">
              <a [routerLink]="['/login']" class="text-danger"
                >Log in existing account</a
              >
            </h6>
          </div>
          <div class="modal-footer clearfix">
            <div class="float-right">
              <button
                *ngIf="!recoverPasswordForm"
                [disabled]="!forgotPasswordForm.valid"
                type="button"
                (click)="forgotPassword()"
                class="btn btn-primary btn-lg"
              >
                Recover Password
              </button>
              <button
                *ngIf="recoverPasswordForm"
                [disabled]="
                  !updatePasswordForm.valid || !forgotPasswordForm.valid
                "
                type="button"
                (click)="updatePassword()"
                class="btn btn-primary btn-lg"
              >
                Recover Password
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="text-center text-white opacity-8 mt-3">
        Copyright © Applebrie {{ currentYear }}
      </div> -->
    </div>
  </div>
</div>
