import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from 'projects/crm/src/environments/environment'

@Injectable({
  providedIn: 'root',
})
export class HttpClientService {
  baseApiUrl: string = environment.baseApiUrl

  private httpOptions = {}

  constructor(private http: HttpClient) {}

  getHttpOptions(options): object {
    if (options) {
      // Add custom options to http options
      const httpOptions = Object.assign(options, this.httpOptions)
      return httpOptions
    }
    return this.httpOptions
  }

  get<T>(url: string, options?): Observable<T> {
    const apiUrl = `${this.baseApiUrl}/${url}`
    const httpOptions = this.getHttpOptions(options)
    return this.http.get<T>(apiUrl, httpOptions)
  }

  post<T>(url: string, body: any, options?): Observable<T> {
    const apiUrl = `${this.baseApiUrl}/${url}`
    const httpOptions = this.getHttpOptions(options)
    return this.http.post<T>(apiUrl, body, httpOptions)
  }

  put<T>(url: string, body: any): Observable<T> {
    const apiUrl = `${this.baseApiUrl}/${url}`
    return this.http.put<T>(apiUrl, body)
  }

  patch<T>(url: string, body: any, mark?: object): Observable<T> {
    const apiUrl = `${this.baseApiUrl}/${url}`
    return this.http.patch<T>(apiUrl, body, mark)
  }

  delete<T>(url: string): Observable<T> {
    const apiUrl = `${this.baseApiUrl}/${url}`
    return this.http.delete<T>(apiUrl)
  }
}
