import { PermissionsRadioStatus } from './../../main/components/scenes/configuration/shared/enums/permissions.enum'
import { Directive, OnDestroy } from '@angular/core'
import { Subject } from 'rxjs'
/**
 * Base component is used for unsubscribe from observables
 * in extended compnents when component is destroyed.
 * Obsevable.pipe(takeUntil(this.ngUnsubscribe)).subscribe()
 */
@Directive()
export class BaseComponentDirective implements OnDestroy {
  /**
   * Global variable for hide/show logic in input (type='password')
   */
  fieldTextType
  /**
   * Global subscriber trigger
   */
  ngUnsubscribe = new Subject<void>()
  /**
   * Permission status
   */
  PermissionsRadioStatus = PermissionsRadioStatus
  /**
   * Page permission status
   */
  pagePermission: PermissionsRadioStatus
  /**
   * Unsbscribe all subscribtions on destroy
   */
  ngOnDestroy(): void {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }
}
