import { Injectable } from '@angular/core'
import { Query } from '@datorama/akita'
import { TableStore, TableState } from './table.store'

@Injectable({ providedIn: 'root' })
export class TableQuery extends Query<TableState> {
  tableConfig$ = this.select([
    'tableName',
    'matSortDisabled',
    'paginatorDisabled',
    'length',
    'pageSizeOptions',
    'pageIndex',
    'pageSize',
    'displayedColumns',
  ])

  tableName$ = this.select('tableName')

  tableId$ = this.select('tableId')

  tableNameAndId$ = this.select(['tableId', 'tableName'])

  tableIdAndMultiSelect$ = this.select(['tableId', 'multiSelect'])

  tableColumns$ = this.select('displayedColumns')

  pageColumns$ = this.select('pageColumns')

  tableIdAndPageColumns$ = this.select(['tableId', 'pageColumns'])

  tableOptions$ = this.select('tableOptions')

  tableAction$ = this.select('tableAction')

  reqParams$ = this.select([
    'active',
    'direction',
    'pageIndex',
    'pageSize',
    'search',
    'filters',
  ])

  filters$ = this.select('filters')

  search$ = this.select('search')

  isSearchable$ = this.select('isSearchable')

  //////////////////////////

  columnDefs$ = this.select('columnDefs')
  isAgGridandTableId$ = this.select(['tableId', 'isAgGrid'])
  data$ = this.select('data')

  constructor(override store: TableStore) {
    super(store)
  }
}
