import { PermissionsRadioStatus } from './../../main/components/scenes/configuration/shared/enums/permissions.enum'
import { BaseComponentDirective } from './../components/base.component'
import { PermissionsService } from './../../main/components/scenes/configuration/shared/services/permissions.service'
import { Injectable } from '@angular/core'
import { takeUntil } from 'rxjs/operators'
import { MainResponseStatus } from '../enums/main-response-status'
import { BehaviorSubject, Observable } from 'rxjs'
import { UserQuery } from '../store/user-store/user.query'

@Injectable({
  providedIn: 'root',
})
export class ScenesPermissionsService extends BaseComponentDirective {
  permissions = new BehaviorSubject(null)
  permissionsSubject = this.permissions.asObservable()
  userId$: Observable<any>

  constructor(
    private permissionsService: PermissionsService,
    private userQuery: UserQuery
  ) {
    super()
    this.userId$ = this.userQuery.userId$
  }

  subscribeToUserId() {
    this.userId$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(state => {
      if (state) {
        this.getPermissions(+state, 'user')
      }
    })
  }

  getPermissions(id, type) {
    this.permissionsService
      .getPermission(id, type)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(result => {
        if (result.status === MainResponseStatus.Success) {
          this.permissions.next(result.data)
        }
      })
  }

  checkPermissionState(
    permissionId: number,
    moduleId: number = null,
    actionId: number = null
  ): PermissionsRadioStatus {
    let accessType
    let permission
    let module
    let action
    if (permissionId && this.permissions.value) {
      permission = this.permissions.value.find(item => {
        return item.permissionId === permissionId
      })
      if (permission) {
        accessType = permission.inherit
          ? permission.roleAccessType
          : permission.accessType
      } else {
        accessType = null
        console.error(
          `Can't find permission with permissionId --> ${permissionId}`
        )
      }
    }

    if (permission && permission.permissionModules.length && moduleId) {
      module = permission.permissionModules.find(item => {
        return item.moduleId === moduleId
      })
      if (module) {
        accessType = module.inherit ? module.roleAccessType : module.accessType
      } else {
        accessType = null
        console.error(
          `Can't find permission with moduleId --> ${moduleId} in permissionId ${permissionId}`
        )
      }
    }

    if (module && module.actions.length && actionId) {
      action = module.actions.find(item => {
        return item.actionId === actionId
      })
      if (action) {
        accessType = action.inherit ? action.roleAccessType : action.accessType
      } else {
        accessType = null
        console.error(
          `Can't find permission with actionid --> ${actionId} in moduleId ${moduleId}`
        )
      }
    }

    return accessType ? accessType : null
  }
}
