import { Injectable } from '@angular/core'
import { CanLoad, Router } from '@angular/router'
import { USER } from 'projects/crm/src/app/shared/service/constants'

/**
 * Regular Guard implementation.
 * Will prevent navigation to route if
 * user is not authorized
 */
@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanLoad {
  /**
   * Router will navigate to login page
   */

  constructor(private router: Router) {}

  /**
   * If logged in so return true,
   * if not redirect to login page with the return url
   */
  canLoad(): boolean {
    const { token, refreshToken } = JSON.parse(localStorage.getItem(USER))
    if (token && refreshToken) {
      return true
    }
    this.router.navigate(['/login'])
    return false
  }
}
