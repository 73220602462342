import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class ThemeOptions {
  staticMenu = false
  sidebarHover = false
  toggleSidebar = false
  toggleSidebarMobile = false
  toggleHeaderMobile = false
  toggleThemeOptions = false
  toggleDrawer = false
  toggleFixedFooter = false
  toggleRightSidebar = false
  toggleRightSidebarForRedirect = false
}
