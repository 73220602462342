<!-- <amplify-authenticator> -->
<!-- <app-main class="p-grid"></app-main> -->
<div [ngStyle]="{ position: 'relative' }">
  <router-outlet></router-outlet>
  <ng-container *ngIf="publicService.loadingForDestinationAccount">
    <ngx-loading
      *ngIf="show$"
      [show]="show$ | async"
      [config]="config$ | async"
    ></ngx-loading>
  </ng-container>
</div>
<!-- </amplify-authenticator> -->
