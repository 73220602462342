import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClientService } from 'projects/crm/src/app/shared/service/http-client.service'
import { Permission } from '../models/permissions.model'
import { MainResponse } from 'projects/crm/src/app/shared/models'

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  constructor(private httpClientService: HttpClientService) {}

  public getPermission(
    id: number,
    permissionType: string
  ): Observable<MainResponse<Permission[]>> {
    return this.httpClientService.get<MainResponse<Permission[]>>(
      `${permissionType}/${id}/permission`
    )
  }

  public updatePermission(
    data,
    id: number,
    permissionType: string
  ): Observable<MainResponse<string>> {
    return this.httpClientService.put<MainResponse<string>>(
      `${permissionType}/${id}/permission`,
      data
    )
  }
}
