import { Observable, Subject } from 'rxjs'
import { Injectable } from '@angular/core'
import { MainResponse } from '../models/main-response.model'
import { MainMenu } from '../models'
import { HttpClientService } from './http-client.service'

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  url = ''

  constructor(private httpClientService: HttpClientService) {}

  //declare a subject instance
  private subject = new Subject<any>()

  //Value is any string message or boolean value
  //this will notify that you have added or deleted the data successfully and you //want other component to listen that
  sendNotification(value: boolean) {
    this.subject.next({ update: value })
  }

  //this will be subscribed by the listing component which needs to display the //added/deleted ie updated list.

  getNotification() {
    return this.subject.asObservable()
  }

  getMenu(): Observable<MainResponse<MainMenu[]>> {
    const url = `${this.url}menu`
    return this.httpClientService.get<MainResponse<MainMenu[]>>(url)
  }
}
