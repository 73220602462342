import { Injectable } from '@angular/core'
import { HttpClient, HttpBackend } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from 'projects/crm/src/environments/environment'
import { HttpErrorResponse } from '@angular/common/http'

// The only difference here
// is that HttpBackend will dispatch
// requests directly to the backend,
// without going through the INTERCEPTOR chain.

@Injectable({
  providedIn: 'root',
})
export class HttpBackendService {
  baseApiUrl: string = environment.baseApiUrl

  private http: HttpClient

  private httpOptions = {}

  constructor(httpBackend: HttpBackend) {
    this.http = new HttpClient(httpBackend)
  }

  getHttpOptions(options): object {
    if (options) {
      // Add custom options to http options
      const httpOptions = Object.assign(options, this.httpOptions)
      return httpOptions
    }
    return this.httpOptions
  }

  get<T>(url: string, options?): Observable<T> {
    const apiUrl = `${this.baseApiUrl}/${url}`
    const httpOptions = this.getHttpOptions(options)
    return this.http.get<T>(apiUrl, httpOptions)
  }

  post<T>(url: string, body: any, options?): Observable<T> {
    const apiUrl = `${this.baseApiUrl}/${url}`
    const httpOptions = this.getHttpOptions(options)
    return this.http.post<T>(apiUrl, body, httpOptions)
  }

  put<T>(url: string, body: any, options?): Observable<T> {
    const apiUrl = `${this.baseApiUrl}/${url}`
    const httpOptions = this.getHttpOptions(options)
    return this.http.put<T>(apiUrl, body, httpOptions)
  }

  patch<T>(url: string, body: any): Observable<T> {
    const apiUrl = `${this.baseApiUrl}/${url}`
    return this.http.patch<T>(apiUrl, body)
  }

  delete<T>(url: string): Observable<T> {
    const apiUrl = `${this.baseApiUrl}/${url}`
    return this.http.delete<T>(apiUrl)
  }

  handleError(err: HttpErrorResponse): any {
    if (err) {
      console.log(
        `http Error: ${err.status}(${err.statusText}) Url:${err.url} message:${err.message}`
      )
      if (err.error) {
        console.log(
          `http Error: ${err.error.message} (${err.error.messageDetail})`
        )
      }
    } else {
      console.log(`http Error: ${err.status}(${err.statusText}) Url:${err.url}`)
    }
  }
}
