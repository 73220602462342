import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { BrandSwitchComponent } from './brand-switch/brand-switch.component'
import { ConfirmationModalComponent } from './modals/confirmation-modal/confirmation-modal/confirmation-modal.component'
import { PageTitleComponent } from './page-title/page-title.component'
import { TableOptionsComponent } from './table-options/table-options.component'
import {
  PerfectScrollbarModule,
  PerfectScrollbarConfigInterface,
} from 'ngx-perfect-scrollbar'
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { TabsComponent } from './tabs/tabs.component'
import { RouterModule } from '@angular/router'
import { TagComponent } from './tag/tag.component'
import { ColumnOrderingComponent } from './column-ordering/column-ordering.component'
import { DragDropModule } from '@angular/cdk/drag-drop'
import { EditableInputComponent } from './editable-input/editable-input.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { EllipsisMenuComponent } from './ellipsis-menu/ellipsis-menu.component'
import { DrawerComponent } from './drawer/drawer.component'
import { TableFilterComponent } from './table-filter/table-filter.component'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { NgSelectModule } from '@ng-select/ng-select'
import { FiltersComponent } from './filters/filters.component'
import { TableHeaderComponent } from './table-header/table-header.component'
import { PipeModule } from '../shared/pipe/pipe.module'
import { InputSwitchComponent } from './input-switch/input-switch.component'
import { NoPermissionComponent } from './no-permission/no-permission.component'
import { DepositDetailsPopUpComponent } from '../main/components/scenes/events/components/deposit-details-pop-up/deposit-details-pop-up.component'
import { ParkedDepositDetailsPopUpComponentComponent } from '../main/components/scenes/events/components/parked-deposit-details-pop-up-component/parked-deposit-details-pop-up-component.component'
import { NoDataComponent } from './no-data/no-data.component'
import { OptionsComponent } from './options/options.component'
import { TotalSplitWidgetComponent } from './total-split-widget/total-split-widget.component'
import { IpWhitelistingComponent } from './ip-whitelist/ip-whitelisting/ip-whitelisting.component'
import { MatExpansionModule } from '@angular/material/expansion'
import { AggridOptionsComponent } from './ag-grid-options/ag-grid-options.component';
import { MonthDaysSelectFieldComponent } from './month-days-select-field/month-days-select-field.component'
import { RightSidebarComponent } from './right-sidebar/right-sidebar.component'
import { ImageUploadAndCropeComponent } from './image-upload-and-crope/image-upload-and-crope.component'
import { ImageCropperModule } from 'ngx-image-cropper';
import { FormInputComponent } from './form-input/form-input.component';
import { CommentLogicHintsComponent } from './comment-logic-hints/comment-logic-hints.component'
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { RightSidebarRedirectComponent } from './right-sidebar-redirect/right-sidebar-redirect.component'

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
}

@NgModule({
  imports: [
    CommonModule,
    PerfectScrollbarModule,
    NgbModule,
    RouterModule,
    DragDropModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    NgSelectModule,
    MatSelectModule,
    MatInputModule,
    PipeModule,
    MatExpansionModule,
    ImageCropperModule,
    NgxSkeletonLoaderModule

  ],
  declarations: [
    BrandSwitchComponent,
    ConfirmationModalComponent,
    PageTitleComponent,
    TableOptionsComponent,
    TabsComponent,
    TagComponent,
    ColumnOrderingComponent,
    EditableInputComponent,
    EllipsisMenuComponent,
    DrawerComponent,
    TableFilterComponent,
    FiltersComponent,
    TableHeaderComponent,
    InputSwitchComponent,
    NoPermissionComponent,
    DepositDetailsPopUpComponent,
    ParkedDepositDetailsPopUpComponentComponent,
    NoDataComponent,
    OptionsComponent,
    TotalSplitWidgetComponent,
    IpWhitelistingComponent,
    AggridOptionsComponent,
    MonthDaysSelectFieldComponent,
    RightSidebarComponent,
    ImageUploadAndCropeComponent,
    FormInputComponent,
    CommentLogicHintsComponent,
    RightSidebarRedirectComponent,
    
  ],
  exports: [
    BrandSwitchComponent,
    ConfirmationModalComponent,
    PageTitleComponent,
    TableOptionsComponent,
    TabsComponent,
    TagComponent,
    ColumnOrderingComponent,
    EditableInputComponent,
    EllipsisMenuComponent,
    DrawerComponent,
    TableFilterComponent,
    FiltersComponent,
    TableHeaderComponent,
    InputSwitchComponent,
    NoPermissionComponent,
    DepositDetailsPopUpComponent,
    ParkedDepositDetailsPopUpComponentComponent,
    NoDataComponent,
    OptionsComponent,
    TotalSplitWidgetComponent,
    IpWhitelistingComponent,
    AggridOptionsComponent,
    MonthDaysSelectFieldComponent,
    RightSidebarComponent,
    ImageUploadAndCropeComponent,
    ImageCropperModule,
    FormInputComponent,
    CommentLogicHintsComponent,
    RightSidebarRedirectComponent
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      // DROPZONE_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
      // DEFAULT_DROPZONE_CONFIG,
    },
  ],

  entryComponents: [ConfirmationModalComponent],
})
export class AppControlsModule {}
