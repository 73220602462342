import { Injectable } from '@angular/core'
import { Query } from '@datorama/akita'
import { LoadingStore, LoadingState } from './loading.store'

@Injectable({ providedIn: 'root' })
export class LoadingQuery extends Query<LoadingState> {
  show$ = this.select('show')
  config$ = this.select('config')
  constructor(override store: LoadingStore) {
    super(store)
  }
}
