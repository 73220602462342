import { AbstractControl } from '@angular/forms'

export function emailValidator(
  control: AbstractControl
): { [key: string]: boolean } | null {
  const emailPattern = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  if (!emailPattern.test(control.value)) {
    return { ' email ': true }
  }
  return null
}
