import { MenuService } from './shared/service/menu.service'
import { BsModalRef, ModalModule, BsModalService} from 'ngx-bootstrap/modal'
import { BrowserModule, Title } from '@angular/platform-browser'
import { APP_INITIALIZER, NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
// App Modules
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'

// Bootstarp
// import { NgBootstrapFormValidationModule } from 'ng-bootstrap-form-validation'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { NgxLoadingModule } from 'ngx-loading'
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgSelectModule } from '@ng-select/ng-select'

import { ThemeOptions } from './theme-options'

import { AkitaNgDevtools } from '@datorama/akita-ngdevtools'
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store'

import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { LoginComponent } from './auth/login/login.component'

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { AppHttpInterceptor } from './shared/service/http.interceptor'
import { ToastrModule } from 'ngx-toastr'
import { HttpBackendService } from './shared/service/http-backend.service'
import { AuthService } from './shared/service/auth.service'
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { environment } from '../environments/environment'
import { HttpClientService } from './shared/service/http-client.service'
import { ErrorInterceptor } from './shared/service/error.interceptor'
import { CookieService } from 'ngx-cookie-service'
import {
  DEFAULT_TIMEOUT,
  TimeoutRequestInterceptor,
} from './shared/service/timeout-request.interceptor'
import { AppInitService } from './shared/service/app-init.service'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { LicenseManager } from 'ag-grid-enterprise'
import { AgGridDatepickerComponent } from './shared/components/ag-grid-datepicker/ag-grid-datepicker.component'
// import { CKEditorModule } from 'ckeditor4-angular'
import { ConfirmDialogComponent } from './shared/components/confirm-dialog/confirm-dialog.component'
import { SweetAlertService } from './shared/service/sweet-alert.service'
import { RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common'
import { AppControlsModule } from './app-controls/app-controls.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store'
import { CoreModule } from 'projects/shared/core/src/core.module'

LicenseManager.setLicenseKey(
  'CompanyName=Applebrie RA LLC,LicensedApplication=crm.gambacrm.com,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-026802,ExpiryDate=29_March_2023_[v2]_MTY4MDA0NDQwMDAwMA==ed0969dc5196415c42d07336183a6857'
)

export function initializeApp(appInitService: AppInitService) {
  return (): Promise<any> => {
    return appInitService.init().then(res => console.log(res))
  }
}
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    AgGridDatepickerComponent,
    ConfirmDialogComponent,
    //  Layout
  ],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserModule,
    NgbModule ,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    // App Modules
    AppControlsModule,
    // Angular Bootstrap Components

    SweetAlert2Module.forRoot(),
    NgSelectModule,
    ModalModule.forRoot(),
    // NgBootstrapFormValidationModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    MatCheckboxModule,
    NgbModule,
    FontAwesomeModule,
    AkitaNgRouterStoreModule,
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      tapToDismiss: true,
      extendedTimeOut: 5000,
    }),
    FontAwesomeModule,
    StoreModule.forRoot({}, {}),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot(),
    CoreModule
    // CKEditorModule,
  ],
  providers: [
    BsModalService,
    Title,
    HttpClientService,
    HttpBackendService,
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: DEFAULT_TIMEOUT,
      useValue: 60 * 1000,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TimeoutRequestInterceptor,
      multi: true,
    },
    AppInitService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
      deps: [AppInitService],
    },
    ThemeOptions,
    MenuService,
    AuthService,
    BsModalRef,
    SweetAlertService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {}
}
