import { BrandSwitchQuery } from './../../app-controls/brand-switch/_store/brand-switch.query'
import { LoadingStore } from './../store/loading-store/loading.store'
import { MainResponseStatus } from './../enums/main-response-status'
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpResponse,
  HttpHeaders,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { tap, finalize, mergeMap, skipWhile, take } from 'rxjs/operators'
import { ToastrService } from 'ngx-toastr'
import { USER } from 'projects/crm/src/app/shared/service/constants'

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  userInfo$: Observable<any>
  token$: Observable<any> | undefined
  private requests: HttpRequest<any>[] = []

  generateUUID() { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if(d > 0){//Use timestamp until depleted
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req)
    if (i >= 0) {
      this.requests.splice(i, 1)
    }

    this.loadingStore.update({
      show: this.requests.length > 0,
    })
  }

  constructor(
    public toasterService: ToastrService,
    public loadingStore: LoadingStore,
    public brandSwitchQuery: BrandSwitchQuery
  ) {
    this.userInfo$ = this.brandSwitchQuery.userInfo$.pipe(
      skipWhile(state => !!state.brandId === false),
      take(1)
    )
  }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {


    if (
      (req.method === 'GET' && !req.url.includes('savedQuery')) /// todo create logic for ag grid get list logic
      || req.url.includes("noLoading=true")
    ) {
        this.loadingStore.update({
          show: false,
        })
      } else {
        this.loadingStore.update({
          show: true,
        })
        this.requests.push(req)
      }


    const { token } = JSON.parse(localStorage.getItem(USER))
    return this.userInfo$.pipe(
      mergeMap(state => {
        // this.loadingStore.update({
        //   show: !req.reportProgress,
        // })

        const customHeadersReq = req.clone({
          headers: new HttpHeaders({
            'X-BrandId': `${state.brandId}`,
            'X-Authorization': `${token}`,
            'Accept-Language': `${state.languageId}`,
            'X-CurrencyISOCode': `${state.currencyId}`,
            'X-SessionId': this.generateUUID()
          }),
        })

        return (
          next
            .handle(customHeadersReq)
            .pipe(
              tap(evt => {
                if (evt instanceof HttpResponse) {
                  if (evt.body.message) {
                    switch (evt.body.status) {
                      case MainResponseStatus.Success:
                        this.toasterService.success(evt.body.message, 'Success')
                        break
                      case MainResponseStatus.Warning:
                        let message = evt.body.message?.startsWith('NO_ACCESS:') ? evt.body.message.slice(10) : evt.body.message;
                        this.toasterService.warning(message, 'Warning')
                        break
                      case MainResponseStatus.Error:
                        this.toasterService.error(evt.body.message, 'Error')
                        break
                    }
                  }
                }
              })
            )
            // .pipe(finalize(() => this.loadingStore.update({ show: false })))
            .pipe(finalize(() => this.removeRequest(req)))
        )
      })
    )
  }
}
