import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ToNamePipe } from './idToName.pipe'
import { SafePipe } from './safe.pipe'
import { FindPipe } from './find.pipe'
import { ToFixedPipe } from './toFixed.pipe'
import { Base64ConvertorPipe } from './base64-covertor.pipe'

@NgModule({
  declarations: [ToNamePipe, SafePipe, FindPipe, Base64ConvertorPipe, ToFixedPipe],
  imports: [CommonModule],
  exports: [ToNamePipe, SafePipe, FindPipe, Base64ConvertorPipe, ToFixedPipe],
})
export class PipeModule {}
