import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { enableAkitaProdMode } from '@datorama/akita'

import { AppModule } from './app/app.module'
import { environment } from './environments/environment'

// Angular Amplify
// import Amplify from 'aws-amplify'
// import amplify from './aws-exports'
// Amplify.configure(amplify)
// const script = document.createElement('script');
// script.async = true;
// script.src = `https://www.googletagmanager.com/gtag/js?id=G-QLCB9KXT94`; // Замените на ваш Tracking ID или Measurement ID
// document.head.appendChild(script);

if (environment.production) {
  enableProdMode()
  enableAkitaProdMode()
}

  // script.onload = () => {
  //   window['gtag']('js', new Date());
  //   window['gtag']('config', 'G-QLCB9KXT94'); // Замените на ваш Tracking ID или Measurement ID
  
  //   platformBrowserDynamic()
  //     .bootstrapModule(AppModule)
  //     .catch((err) => console.error(err));
  // };

  platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));