import { Component } from '@angular/core'
import { AuthService } from '../../shared/service/auth.service'
import { MainResponseStatus } from '../../shared/enums/main-response-status'
import { Router } from '@angular/router'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { emailValidator } from '../../shared/validators/email.directive'

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
  forgotPasswordForm: FormGroup
  recoverPasswordForm = false
  updatePasswordForm: FormGroup
  showPassError = false
  currentYear = new Date().getFullYear()
  constructor(
    private router: Router,
    private authService: AuthService,
    public fb: FormBuilder
  ) {
    this.forgotPasswordForm = fb.group({
      email: ['', [Validators.required, emailValidator]],
    })
    this.updatePasswordForm = fb.group({
      code: ['', Validators.required],
      newPassword: ['', Validators.required],
      password: ['', Validators.required],
    })
  }

  checkPassword(group: FormGroup) {
    const password = group.get('password').value
    const newPassword = group.get('newPassword').value
    return password === newPassword ? null : true
  }

  updatePassword() {
    if (this.checkPassword(this.updatePasswordForm)) {
      this.showPassError = true
    } else {
      const payload = {
        email: this.forgotPasswordForm.get('email').value,
        code: this.updatePasswordForm.get('code').value,
        newPassword: this.updatePasswordForm.get('newPassword').value,
      }
      this.authService.updatePassword(payload).subscribe(result => {
        if (result.status === MainResponseStatus.Success) {
          this.router.navigate(['/login'])
        }
      })
    }
  }

  forgotPassword() {
    const payload = { ...this.forgotPasswordForm.value }
    this.authService.forgotPassword(payload).subscribe(result => {
      if (result.status === MainResponseStatus.Success) {
        this.recoverPasswordForm = true
      }
    })
  }
}
