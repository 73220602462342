import { Router } from '@angular/router'
import { AuthService } from './auth.service'
import { Injectable } from '@angular/core'
import { USER } from './constants'
import { MainResponseStatus } from '../../shared/enums/main-response-status'

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  constructor(private authService: AuthService, private router: Router) {}
  handle401Error() {
    const user = JSON.parse(localStorage.getItem(USER))
    const payload = {
      refreshToken: user.refreshToken,
    }

    this.authService.getAccessTokenByRefreshToken(payload).subscribe(
      result => {
        if (result.status === MainResponseStatus.Success) {
          user.token = result.data
          localStorage.setItem(USER, JSON.stringify(user))
          location.reload() // temporary solution
        } else {
          this.router.navigate(['login'])
        }
      },
      () => this.router.navigate(['login'])
    )
  }
}
