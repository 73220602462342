import { Injectable } from '@angular/core'
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http'
import { Observable, of } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { ErrorHandlerService } from './error-handler.service'

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private errorHandlerService: ErrorHandlerService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err: any) => {
        if (err.status === 401) {
          this.errorHandlerService.handle401Error()
        }
        if (err.status === 403) {
          console.log('403')
        }
        if (err instanceof HttpErrorResponse) {
          try {
            console.log('Something went wrong', 'Error')
          } catch (e) {
            console.log('Something went wrong', 'Error')
          }
        }
        return of(err)
      })
    )
  }
}
