import { Injectable } from '@angular/core'
import { Store, StoreConfig } from '@datorama/akita'
import { USER } from 'projects/crm/src/app/shared/service/constants'

export interface UserState {
  token: string
  refreshToken: string
  userId: number
  username: string
  firstname: string
  lastname: string
  email: string
  mustBeEnabledTwoFA: boolean
}

export function createInitialState(): UserState {
  return {
    token: '',
    refreshToken: '',
    userId: null,
    username: '',
    firstname: '',
    lastname: '',
    email: '',
    mustBeEnabledTwoFA: false,
  }
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: USER, resettable: true })
export class UserStore extends Store<UserState> {
  constructor() {
    super(createInitialState())
    this.loadFromStorage()
  }

  loadFromStorage() {
    const data = JSON.parse(localStorage.getItem(USER))
    if (data) {
      this.update({ ...data })
    }
  }
}
