import { HttpClientService } from './http-client.service'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { MainResponse } from '../models'

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  constructor(private httpClientService: HttpClientService) {}

  public requestTimeOutLog(payload): Observable<MainResponse<null>> {
    const url = 'public/requestTimeOutLog'
    return this.httpClientService.post<MainResponse<null>>(url, payload)
  }
}
