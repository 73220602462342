import { Injectable } from '@angular/core'
import { Store, StoreConfig } from '@datorama/akita'
import { ngxLoadingAnimationTypes, INgxLoadingConfig } from 'ngx-loading'

export interface LoadingState {
  show: boolean
  config: INgxLoadingConfig
}

export function createInitialState(): LoadingState {
  return {
    show: false,
    config: {
      backdropBorderRadius: '10px',
      fullScreenBackdrop: true,
      animationType: ngxLoadingAnimationTypes.wanderingCubes,
      backdropBackgroundColour: 'rgba(0,0,0,0.1)',
      primaryColour: '#fff',
      secondaryColour: '#fff',
      tertiaryColour: '#fff',
    },
  }
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'loading' })
export class LoadingStore extends Store<LoadingState> {
  constructor() {
    super(createInitialState())
  }
}
