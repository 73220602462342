import { Injectable } from '@angular/core'
import { Store, StoreConfig } from '@datorama/akita'
import { ColDef, ColGroupDef, ColumnState } from 'ag-grid-community'
import { ColumnOrdering, TableOptionsDetails } from '../../models'

export interface TableFilter {
  columnId: number
  filterType: number
  value: string
  valueFrom: string
  valueTo: string
  values: string[]
}
export interface TableState {
  tableId: string
  multiSelect: boolean
  tableName: string
  matSortDisabled?: boolean
  paginatorDisabled?: boolean
  selectAll?: boolean
  pageIndex?: number
  length?: number
  pageSizeOptions?: number[]
  active: string
  direction: string
  pageSize: number
  search: string
  displayedColumns: string[]
  filters: TableFilter[]
  pageColumns: ColumnOrdering[]
  tableOptions: TableOptionsDetails[]
  tableAction: string
  isSearchable: boolean

  //////////////////////
  isAgGrid: boolean
  columnDefs: (ColDef | ColGroupDef)[] | null | undefined
  columnState: ColumnState[]
  agGridFilter: Record<string, any>
  data: any
}

export function createInitialState(): TableState {
  return {
    tableId: '0',
    multiSelect: false,
    tableName: '',
    matSortDisabled: true,
    paginatorDisabled: true,
    length: 0,
    pageSizeOptions: [20, 50, 100],
    pageIndex: null,
    active: null,
    direction: null,
    pageSize: null,
    search: null,
    displayedColumns: [],
    filters: [],
    pageColumns: [],
    tableOptions: [],
    tableAction: '',
    isSearchable: false,

    /////////////////////////
    isAgGrid: false,
    columnDefs: [],
    columnState: [],
    agGridFilter: null,
    data: null,
  }
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'table', resettable: true })
export class TableStore extends Store<TableState> {
  constructor() {
    super(createInitialState())
  }
}
