import { Component, HostBinding, OnInit } from '@angular/core'
import { LoadingQuery } from './shared/store/loading-store/loading.query'
import { Observable } from 'rxjs'
import { INgxLoadingConfig } from 'ngx-loading/lib/ngx-loading-config'
import { UserQuery } from './shared/store/user-store/user.query'
import { Title } from '@angular/platform-browser'
import { TableQuery } from './shared/store/table-store/table.query'
import { BaseComponentDirective } from './shared/components/base.component'
import { takeUntil } from 'rxjs/operators'
import { ScenesPermissionsService } from './shared/service/scenes-permissions.service'
import { environment } from '../environments/environment'
import { PublicService } from './shared/service/public.service'
import { Router } from '@angular/router'

// declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends BaseComponentDirective implements OnInit {
  @HostBinding('@.disabled') animationsDisabled = true
  title = 'GambaCRM'
  public show$: Observable<any>
  public config$: Observable<INgxLoadingConfig>
  tableNameAndId$: Observable<any>

  constructor(
    public loadingQuery: LoadingQuery,
    public userQuery: UserQuery,
    public tableQuery: TableQuery,
    private titleService: Title,
    private scenesPermissionsService: ScenesPermissionsService,
    public publicService: PublicService,
    public router: Router
  ) {
    super()
    this.show$ = this.loadingQuery.show$
    this.config$ = this.loadingQuery.config$
    this.tableNameAndId$ = this.tableQuery.tableNameAndId$
    this.scenesPermissionsService.subscribeToUserId()
  //  const navEndEvents =  this.router.events.pipe(filter(event => event instanceof NavigationEnd))
  //  navEndEvents.subscribe((event:NavigationEnd) => {
  //   gtag('config', 'G-QLCB9KXT94'), {
  //     'page_path':event.urlAfterRedirects
  //   }
  //  })
  }
  ngOnInit() {
    const excludedTableIds = ['52', '51']
    this.tableNameAndId$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(({ tableId, tableName }) => {
        if (tableName && !excludedTableIds.includes(tableId)) {
          this.titleService.setTitle(`${this.title} - ${tableName}`)
        }
      })
    if (!environment.production) {
      this.publicService
        .getLateResponse(6 * 1000)
        .subscribe(result => console.log('Response duration is', result))
    }
  }
}
