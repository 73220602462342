export enum PermissionsRadioStatus {
  hide = 1,
  read,
  readAndWrite,
}

export enum ModuleType {
  mainModule = 1,
  childModule,
  action,
}

export enum PermissionType {
  role = 1,
  user,
}
