import { Injectable } from '@angular/core'
import { MainResponseStatus } from '../../shared/enums/main-response-status'
import { PublicService } from './public.service'

@Injectable({
  providedIn: 'root',
})
export class AppInitService {
  redirectionURL = 'https://gambacrm.com'
  constructor(private publicService: PublicService) {}

  init() {
    return new Promise<void>((resolve, reject) => {
      this.publicService.isIpWhitelisted().subscribe(
        result => {
          if (
            result.status === MainResponseStatus.Success &&
            result.data &&
            result.data.isIpWhitelisted
          ) {
            resolve()
          } else {
            const { ip } = result.data
            window.location.href = ip
              ? `${this.redirectionURL}?ip=${ip}`
              : this.redirectionURL
            reject()
          }
        },
        () => {
          window.location.href = this.redirectionURL
          reject()
        }
      )
    })
  }
}
