import { Injectable } from '@angular/core'
import { Query } from '@datorama/akita'
import { UserStore, UserState } from './user.store'
import { USER } from 'projects/crm/src/app/shared/service/constants'

@Injectable({ providedIn: 'root' })
export class UserQuery extends Query<UserState> {
  token$ = this.select('token')

  userName$ = this.select(['firstname', 'lastname'])

  userId$ = this.select('userId')

  constructor(override store: UserStore) {
    super(store)
    this.saveToStorage()
  }
  saveToStorage() {
    this.select(state => state).subscribe(state => {
      if (state) {
        localStorage.setItem(USER, JSON.stringify(state))
      }
    })
  }
}
