import { Injectable } from '@angular/core'
import { Store, StoreConfig } from '@datorama/akita'

export interface BrandSwitchState {
  currencyId: string
  languageId: string
  brandId: number
  brandName: string
}

export function createInitialState(): BrandSwitchState {
  return {
    currencyId: 'EUR',
    languageId: 'en',
    brandId: null,
    brandName: '',
  }
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'brand-switch' })
export class BrandSwitchStore extends Store<BrandSwitchState> {
  constructor() {
    super(createInitialState())
    this.loadFromStorage()
  }
  loadFromStorage() {
    const brandId = localStorage.getItem('brandId')
    const languageId = localStorage.getItem('languageId')
    const currencyId = localStorage.getItem('currencyId')

    if (brandId) {
      this.update({ brandId: JSON.parse(brandId) })
      this.update({
        languageId,
        currencyId,
      })
    }
  }
}
