import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpBackendService } from './http-backend.service'
import { MainResponse } from '../models'
import { HttpHeaders } from '@angular/common/http'
import { USER } from './constants'

@Injectable({
  providedIn: 'root',
})
export class TwoFAService {
  user = 'user'
  brand = 'brand'
  brandId = localStorage.getItem('brandId')
  options = {
    headers: new HttpHeaders({
      'X-Authorization': `${JSON.parse(localStorage.getItem(USER))?.token}`,
      'X-BrandId': JSON.parse(this.brandId),
    }),
  }
  constructor(public httpBackendService: HttpBackendService) {}

  /**
   * https://applebrie.atlassian.net/wiki/spaces/CRMV3/pages/1177780352/User#Request-1.31%3A-Get-User-2FA
   * Request 1.31: Get User 2FA
   */
  getUserTwoFA(): Observable<MainResponse<boolean>> {
    const url = `${this.user}/CheckUserTwoFactorEnabled`
    return this.httpBackendService.get<MainResponse<boolean>>(url, this.options)
  }

  /**
   * https://applebrie.atlassian.net/wiki/spaces/CRMV3/pages/1177780352/User#Request-1.32%3A-Change-User-2FA
   * Request 1.32: Change User 2FA
   */
  updateTwoFAAuthentication(payload): Observable<MainResponse<string>> {
    const url = `${this.user}/updateTwoFactorAuthentication`
    return this.httpBackendService.put<MainResponse<string>>(
      url,
      payload,
      this.options
    )
  }

  /**
   * https://applebrie.atlassian.net/wiki/spaces/CRMV3/pages/2101641220/Brand#Request-3.28%3A-Check-Brand-2FA-Enabled
   * Request 3.28: Check Brand 2FA Enabled
   */
  checkBrandTwoFAEnabled(): Observable<MainResponse<any>> {
    const url = `${this.brand}/CheckTwoFAEnabled`
    return this.httpBackendService.get<MainResponse<any>>(url, this.options)
  }
}
