import { LoggerService } from './logger.service'
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http'
import { Inject, Injectable, InjectionToken } from '@angular/core'
import { Observable, throwError, TimeoutError } from 'rxjs'
import { catchError, timeout } from 'rxjs/operators'
import { ToastrService } from 'ngx-toastr'

export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout')

@Injectable()
export class TimeoutRequestInterceptor implements HttpInterceptor {
  constructor(
    @Inject(DEFAULT_TIMEOUT) private timeoutInterval: number,
    public toasterService: ToastrService,
    public loggerService: LoggerService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      timeout(this.timeoutInterval),
      catchError(err => {
        if (err instanceof TimeoutError) {
          const { body, urlWithParams, method } = req

          const headers = req.headers
            .keys()
            .filter(header => header !== 'X-Authorization')
            .map(item => {
              return `${item}:${req.headers.get(item)}`
            })
            .toString()

          const logInfo = {
            requestBody: body,
            requestHeaders: headers,
            requestUrl: urlWithParams,
            requestMethod: method,
          }

          this.loggerService.requestTimeOutLog(logInfo).subscribe()

          this.toasterService.error(
            'Sorry, something went wrong, please reload the page',
            'Error'
          )
        }
        return throwError(err)
      })
    )
  }
}
