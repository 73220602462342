import { Injectable } from '@angular/core'
import { Info } from '../models'
import Swal from 'sweetalert2'

@Injectable({
  providedIn: 'root',
})
export class SweetAlertService {
  swalGlobalOptions = {
    showCancelButton: true,
    reverseButtons: true,
    buttonsStyling: false,
    showCloseButton: false,
  }
  confirmButton: boolean
  constructor() {}

  paymentMethodList(info: any) {
    let tableHtml = ''
    for (let i = 0; i < info.length; i++) {
      tableHtml += `
        <div class="payment-method-details-child">
          <div class="payment-method-details-child-odd">${info[i].key}</div>
          <div class="payment-method-details-child-even">${info[i].value} <img onclick="navigator.clipboard.writeText('${info[i].value}')" style="margin-left: 12px; cursor:pointer" title="Copy link"
          src="../../../../../../../assets/images/copy-icon.png"/></div>
        </div>
        <span style="border: 1px solid #CFE9EE;display: block;margin-bottom: 5px"></span>
      `
    }

    const finalTableHtml = `<div class="payment-method-details-parent">${tableHtml}</div>`
    return Swal.fire({
      title: 'Payment method details',
      html: finalTableHtml,
      customClass: {
        confirmButton: `main_action btn-transition  btn btn-outline-primary`,
        actions: `close-btn-payment-method`,
      },
      confirmButtonText: 'Close',
      showCancelButton: false,
      showConfirmButton: true,
    })
  }

  alert(info: Info, changeType = false) {
    const {
      title,
      text,
      confirmButtonText,
      cancelButtonText,
      showCloseButton,
    } = info
    let type 

    if(changeType) {
      type = 'danger'
    } else {
      type =
      confirmButtonText === 'Delete' || confirmButtonText === 'Remove'
        ? 'danger'
        : 'primary'
    }

    if (confirmButtonText) {
      this.confirmButton = true
    } else {
      this.confirmButton = false
    }

    if (cancelButtonText) {
      this.swalGlobalOptions.showCancelButton = true
    } else {
      this.swalGlobalOptions.showCancelButton = false
    }

    if (showCloseButton) {
      this.swalGlobalOptions.showCloseButton = showCloseButton
    }

    return Swal.fire({
      title,
      text,
      showConfirmButton: this.confirmButton,
      confirmButtonText,
      cancelButtonText,
      customClass: {
        confirmButton: `main_action btn-hover  btn btn-${type}`,
        cancelButton: `main_action btn-transition  btn btn-outline-primary`,
      },
      ...this.swalGlobalOptions,
    })
  }

  selectionAlert(text: string) {
    return Swal.fire({
      title: 'No selections',
      text,
      timer: 4000,
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Back',
      customClass: {
        cancelButton: `main_action btn-transition  btn btn-outline-primary`,
      },
      buttonsStyling: false,
    })
  }

  confirmAlert(text: string, confirmButtonText: string, title?) {
    return Swal.fire({
      title: title ? title : 'Warning',
      text,
      confirmButtonText,
      showConfirmButton: true,
      showCancelButton: false,
      customClass: {
        confirmButton: `main_action btn-hover  btn btn-primary`,
        cancelButton: `main_action btn-transition  btn btn-outline-primary`,
      },
      buttonsStyling: false,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Back',
    })
  }
  URLcopyAlert(text: string) {
    return Swal.fire({
      title: 'Landiing page URL',
      text:text,
      showConfirmButton: true,
      showCancelButton: true,
      customClass: {
        confirmButton: `main_action btn-transition  btn btn-outline-primary`,
        cancelButton: `main_action btn-hover  btn btn-primary`,
        title:`text-left`,
      },
      buttonsStyling: false,
      cancelButtonText: 'Copy URL',
      confirmButtonText:'Close'
    })
  }

  statusEmailAlert(text: string) {
    return Swal.fire({
      title: 'Warning',
      text,
      showCancelButton: true,
      showConfirmButton: true,
      reverseButtons: true,
      customClass: {
        cancelButton: `main_action btn-transition  btn btn-outline-primary`,
        confirmButton: `main_action btn-hover  btn btn-primary`,
        title: `confirm-title`,
      },
      buttonsStyling: false,
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Confirm',
    })
  }

  UiConfigAlert() {
    return Swal.fire({
      title: '',
      text: 'Your changes have been successfully saved!',
      timer: 2000,
      showConfirmButton: false,
      showCancelButton: false,
      customClass: {
        container: 'confirmAletUiConfig',
        confirmButton: `main_action btn-hover  btn btn-primary`,
        cancelButton: `main_action btn-transition  btn btn-outline-primary`,
      },
      buttonsStyling: false,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Back',
    })
  }

  withdrawalStatusConfirmation(text: string) {
    return Swal.fire({
      title: 'Confirmation',
      text,
      showConfirmButton: true,
      showCancelButton: true,
      customClass: {
        confirmButton: `confirm`,
        cancelButton: `cancel`,
        title: `confirm-title`,
         container: `confirm-text`,
      },
      confirmButtonText: '✓ Confirm',
      cancelButtonText: '✗ Cancel',
    })
  }

  changeTab(text: string) {
    return Swal.fire({
      title: 'Confirmation',
      text,
      showConfirmButton: true,
      showCancelButton: true,
      customClass: {
        confirmButton: `confirm`,
        cancelButton: `cancel`,
        title: `confirm-title`,
         container: `confirm-text`,
      },
      confirmButtonText: 'Leave',
      cancelButtonText: 'Cancel',
    })
  }

  inputPopUp(
    title: string,
    text: string,
    inputValue: string,
    cancelButtonText: string,
    confirmButtonText: string
  ) {
    return Swal.fire({
      title,
      text,
      input: 'text',
      inputValue,
      showCancelButton: true,
      showConfirmButton: true,
      cancelButtonText,
      confirmButtonText,
      showCloseButton: true,
      customClass: {
        popup: `doc-details-input-popup`,
        title: `doc-details-input-popup-title`,
        container: `doc-details-input-popup-content`,
        input: `doc-details-input-popup-input`,
        actions: `doc-details-input-popup-actions`,
        confirmButton: `doc-details-input-popup-confirm-button`,
        cancelButton: `doc-details-input-popup-cancel-button`,
        closeButton: `doc-details-input-popup-close-button`,
      },
      // not supported anymore
      // onOpen: () => {
        // Swal.getInput().blur()
      // },
    })
  }

  success() {
    Swal.fire({
      title: 'Success!',
      text: 'The action completed successfully!',
      icon: 'success',
      customClass: {
        popup: `success-popup`,
      },
    })
  }

  error() {
    Swal.fire({
      title: 'Sorry!',
      text: 'Something went wrong!',
      icon: 'error',
      customClass: {
        popup: `success-popup`,
      },
    })
  }

  delete(
    title: string,
    text: string,
    cancelButtonText: string,
    confirmButtonText: string
  ) {
    return Swal.fire({
      title,
      text,
      cancelButtonText,
      confirmButtonText,
      showConfirmButton: true,
      showCancelButton: true,
      customClass: {
        popup: `delete-popup`,
        title: `delete-popup-title`,
         container: `delete-popup-content`,
        actions: `delete-popup-actions`,
        confirmButton: `delete-popup-confirm-button`,
        cancelButton: `delete-popup-cancel-button`,
      },
    })
  }
}
