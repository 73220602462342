import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { MainResponse } from '../models'
import { HttpBackendService } from './http-backend.service'

@Injectable({
  providedIn: 'root',
})
export class PublicService {
  url = 'public'
  loadingForDestinationAccount = true

  constructor(private httpBackendService: HttpBackendService) {}

  /**
   * https://applebrie.atlassian.net/wiki/spaces/CRMV3/pages/1215463439/Public+Requests#Request-1.3%3A-Get-a-late-response-from-Gateway
   * Request 1.3: Get a late response from Gateway
   */
  getLateResponse(milliseconds): Observable<MainResponse<number>> {
    const url = `${this.url}/delay/${milliseconds}`
    return this.httpBackendService.get(url)
  }

  /**
   * https://applebrie.atlassian.net/wiki/spaces/CRMV3/pages/1215463439/Public+Requests#Request-1.9%3A-Get-is-client-IP-whitelisted
   * Request 1.9: Get is client IP whitelisted
   */
  isIpWhitelisted(): Observable<MainResponse<any>> {
    const url = `${this.url}/isWhitelisted`
    return this.httpBackendService.get(url)
  }
}
