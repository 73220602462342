import { Component, OnInit } from '@angular/core'
import { AuthService } from '../../shared/service/auth.service'
import { MainResponseStatus } from '../../shared/enums/main-response-status'
import { UserStore } from '../../shared/store/user-store/user.store'
import { Router } from '@angular/router'
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
  FormControl,
} from '@angular/forms'
import { ToastrService } from 'ngx-toastr'
import { emailValidator } from '../../shared/validators/email.directive'
import { NoSpace } from 'projects/crm/src/app/shared/validators/regexp'
import { TwoFAService } from '../../shared/service/two-fa.service'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup
  fieldTextType: boolean
  enableTWOFA: boolean = false
  timeIsExpired: boolean = false
  currentYear = new Date().getFullYear()

  constructor(
    private router: Router,
    private authService: AuthService,
    private userStore: UserStore,
    public fb: FormBuilder,
    public toasterService: ToastrService,
    public twoFAService: TwoFAService
  ) {
    this.loginForm = fb.group({
      email: ['', [Validators.required, emailValidator]],
      password: ['', [Validators.required, Validators.pattern(NoSpace)]],
    })
  }

  ngOnInit() {
    this.userStore.reset()
    // this.checkTwoFAStatus() //twofa
  }

  navigateToHomePage() {
    const link = ''
    this.router.navigate([link])
  }

  reset(result) {
    this.userStore.reset()
    this.loginForm.reset()
    this.toasterService.error(result.message, 'Error', {
      positionClass: 'toast-top-center',
    })
  }

  accept(result) {
    this.userStore.update({ ...result.data })
    this.navigateToHomePage()
  }

  twoFAAction() {
    this.loginForm.addControl(
      'code',
      new FormControl('', [Validators.required, Validators.pattern(NoSpace)])
    )
    this.enableTWOFA = true
  }

  logIn() {
    this.timeIsExpired = false
    const payload = { ...this.loginForm.value }
    this.authService.logIn(payload).subscribe(result => {
      if (result.status === MainResponseStatus.Success) {
        this.accept(result)
      } else if (
        result.status === MainResponseStatus.Warning &&
        result.data &&
        result.data.loginTypeEnum &&
        result.data.loginTypeEnum === 2
      ) {
        this.toasterService.error(result.message, 'Error', {
          positionClass: 'toast-top-center',
        })
        this.twoFAAction()
      } else {
        this.reset(result)
      }
    })
  }

  trimInputValue(formControl: AbstractControl) {
    formControl.setValue(formControl.value.replace(/\s/g, ''))
  }
}
